<template>
  <article
    ref="user-edit"
    class="user-edit box"
  >
    <VCard class="user-edit__body">
      <VForm
        ref="avatar-form"
        class="fill-height"
        lazy-validation
        data-user-edit-avatar
        @submit.prevent="save('avatar-form')"
      >
        <VAvatar
          class="user-edit__avatar"
          :size="$vuetify.breakpoint.smAndDown ? 80 : 256"
          rounded
        >
          <VImg
            v-if="profile.photo"
            :src="`/${profile.photo}`"
            :alt="account.title"
          />
          <VIcon v-else>
            person
          </VIcon>
        </VAvatar>
        <div class="actions">
          <VBtn
            tag="label"
            class="mb-2"
            :title="$t('$app.upload_photo')"
            block
            depressed
            :large="$vuetify.breakpoint.lgAndUp"
          >
            <VIcon
              left
              size="24"
            >
              attach_file
            </VIcon>
            <span>{{ $t('$app.upload_photo') }}</span>
            <input
              name="photo"
              type="file"
              accept="image/*"
              hidden
              @change="uploadPhoto"
            >
          </VBtn>
          <VBtn
            class="mb-2"
            text
            color="error"
            block
            :large="$vuetify.breakpoint.lgAndUp"
            @click="deletePhoto"
          >
            {{ $t('$app.delete_photo') }}
          </VBtn>
          <VDivider />
          <VList class="mt-2">
            <VListItemGroup
              v-model="currentItem"
              :mandatory="$vuetify.breakpoint.mdAndUp"
            >
              <VListItem value="account">
                <VListItemIcon>
                  <VIcon>manage_accounts</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('accounts.one') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="base-information">
                <VListItemIcon>
                  <VIcon>info</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('$app.basic_information') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="contacts-information">
                <VListItemIcon>
                  <VIcon>contact_mail</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('$app.contact_information') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="education">
                <VListItemIcon>
                  <VIcon>school</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('$app.education') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="work">
                <VListItemIcon>
                  <VIcon>work</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('$app.work') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
            </VListItemGroup>
          </VList>
        </div>
      </VForm>

      <h1 class="user-edit__title">
        {{ account.title }}
      </h1>

      <div class="user-edit__content">
        <Component
          :is="component"
          v-bind="{...props}"
        >
          <!-- Аккаунт -->
          <VForm
            v-show="currentItem === 'account'"
            ref="account-form"
            data-tab="account"
            class="fill-height"
            lazy-validation
            @submit.prevent="save('account-form')"
          >
            <VCard flat>
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('accounts.one') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.username"
                      outlined
                      :label="$t('usernames.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length <= 254 || 'Логин должен быть не больше 254 символов'
                      ]"
                      @input="trimField($event, 'username')"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.password"
                      outlined
                      :label="$t('passwords.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length >= 6 || 'Пароль должен быть не меньше 6 символов',
                        v => (v || '').trim().length <= 254 || 'Пароль должен быть не больше 254 символов'
                      ]"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :append-icon="isPasswordVisible ? 'visibility_off' : 'visibility'"
                      @input="trimField($event, 'password')"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VSwitch
                      v-model="profile.generatePassword"
                      :label="$t('generate_new_password.one')"
                    />
                    <VSwitch
                      v-if="account.email && profile.generatePassword"
                      v-model="profile.sendCredentials"
                      label="Выслать новый логин/пароль"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VAutocomplete
                      v-model="profile.timezone"
                      outlined
                      :items="timezoneItems"
                      :label="$t('$app.time_zone')"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save('account-form')"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VForm>

          <!-- Основная информация -->
          <VForm
            v-show="currentItem === 'base-information'"
            ref="base-information-form"
            data-tab="base-information"
            class="fill-height"
            lazy-validation
            @submit.prevent="save('base-information-form')"
          >
            <VCard flat>
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('$app.basic_information') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.title"
                      outlined
                      :label="$t('full_name_person.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов'
                      ]"
                      class="required"
                      @input="trimField($event, 'title')"
                    />
                    <VExpansionPanels>
                      <VExpansionPanel>
                        <VExpansionPanelHeader>
                          {{ $t('$app.full_name.respective') }}
                        </VExpansionPanelHeader>
                        <VExpansionPanelContent>
                          <VTextField
                            v-model="profile.titleRomanized"
                            outlined
                            :label="$t('$app.full_name.latin')"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleRomanized')"
                          />
                          <VTextField
                            v-model="profile.titleGenitive"
                            outlined
                            :label="$t('$app.full_name.genitive')"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleGenitive')"
                          />
                          <VTextField
                            v-model="profile.titleDative"
                            outlined
                            :label="$t('$app.full_name.dative')"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleDative')"
                          />
                          <VTextField
                            v-model="profile.titleAccusative"
                            outlined
                            :label="$t('$app.full_name.accusative')"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleAccusative')"
                          />
                        </VExpansionPanelContent>
                      </VExpansionPanel>
                    </VExpansionPanels>
                  </VCol>
                  <VCol cols="12">
                    <VRadioGroup
                      v-model="profile.gender"
                      :label="$t('gender.one')"
                      row
                      :class="requiredFields.gender && 'required'"
                    >
                      <VRadio
                        :label="$t('gender_female.one')"
                        value="f"
                      />
                      <VRadio
                        :label="$t('gender_male.one')"
                        value="m"
                      />
                    </VRadioGroup>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.dob"
                      v-mask="'##.##.####'"
                      placeholder="ДД.ММ.ГГГГ"
                      outlined
                      :label="$t('dob.one')"
                      :rules="[
                        v => preparedRule(requiredFields.dob, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 63 || 'В дате рождения не должно быть больше 63 символов',
                      ]"
                      :class="requiredFields.dob && 'required'"
                      @input="trimField($event, 'dob')"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VAutocomplete
                      v-model="profile.citizenship"
                      outlined
                      :items="citizenshipItems"
                      item-text="title"
                      item-value="code"
                      :label="$t('citizenship.one')"
                      :rules="[ v => preparedRule(requiredFields.citizenship, v) || (v || '').trim().length > 0 || 'Заполните поле' ]"
                      :class="requiredFields.citizenship && 'required'"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.inn"
                      outlined
                      :label="$t('inn.one')"
                      placeholder="AA BB CCCCC D или AA BB CCCCCC DD"
                      :rules="[
                        v => preparedRule(requiredFields.inn, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => validateINN(v || '')
                      ]"
                      :class="requiredFields.inn && 'required'"
                      @input="updateINN"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      ref="snils"
                      v-model="profile.snils"
                      v-mask="'###-###-### ##'"
                      outlined
                      :label="$t('snils.one')"
                      placeholder="XXX-XXX-XXX YY"
                      :rules="[ v => preparedRule(requiredFields.snils, v) || validateSNILS(v) ]"
                      :class="requiredFields.snils && 'required'"
                      @input="trimField($event, 'snils')"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.phone"
                      outlined
                      :label="$t('phones.one')"
                      type="tel"
                      :rules="[
                        v => preparedRule(requiredFields.phone, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов'
                      ]"
                      :class="requiredFields.phone && 'required'"
                      @input="trimField($event, 'phone')"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.email"
                      outlined
                      :label="$t('emails.one')"
                      type="email"
                      :rules="[
                        v => preparedRule(requiredFields.email, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => !(v || '').length || /.+@.+/.test(v) || 'Невалидный email',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов',
                      ]"
                      :class="requiredFields.email && 'required'"
                      @input="trimField($event, 'email')"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12">
                    <VTextarea
                      v-model="profile.passport"
                      outlined
                      :label="$t('user_profile_passport_info.one')"
                      :placeholder="$t('user_profile_passport_info_placeholder.one')"
                      rows="3"
                      :rules="[
                        v => preparedRule(requiredFields.passport, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов'
                      ]"
                      :class="requiredFields.passport && 'required'"
                      @input="trimField($event, 'passport')"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save('base-information-form')"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VForm>

          <!-- Контактная информация -->
          <VForm
            v-show="currentItem === 'contacts-information'"
            ref="contacts-information-form"
            data-tab="contacts-information"
            class="fill-height"
            lazy-validation
            @submit.prevent="save('contacts-information-form')"
          >
            <VCard flat>
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('$app.contact_information') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.locality"
                      outlined
                      :label="$t('locality_of_a_person.one')"
                      :rules="[
                        v => preparedRule(requiredFields.locality, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов'
                      ]"
                      :class="requiredFields.locality && 'required'"
                      @input="trimField($event, 'locality')"
                    />
                    <VTextarea
                      v-model="profile.address"
                      outlined
                      :label="$t('address_of_a_person.one')"
                      rows="3"
                      :rules="[
                        v => preparedRule(requiredFields.address, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов'
                      ]"
                      :class="requiredFields.address && 'required'"
                      @input="trimField($event, 'address')"
                    />
                    <VTextarea
                      v-model="profile.physicalAddress"
                      outlined
                      :label="$t('physical_address_of_a_person.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      persistent-hint
                      :hint="$t('$app.physical_address_person.hint') + '.'"
                      @input="trimField($event, 'physicalAddress')"
                    />
                    <VTextarea
                      v-model="profile.postalAddress"
                      outlined
                      :label="$t('postal_address_of_a_person.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      persistent-hint
                      :hint="$t('$app.postal_address_of_a_person.hint') + '.'"
                      @input="trimField($event, 'postalAddress')"
                    />
                  </VCol>
                </VRow>
              </VCardText>

              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save('contacts-information-form')"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VForm>

          <!-- Образование -->
          <VForm
            v-show="currentItem === 'education'"
            ref="education-form"
            data-tab="education"
            class="fill-height"
            lazy-validation
            @submit.prevent="save('education-form')"
          >
            <VCard flat>
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('$app.education') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextarea
                      v-model="profile.education"
                      outlined
                      :label="$t('information_education.one')"
                      rows="3"
                      :rules="[
                        v => preparedRule(requiredFields.education, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов'
                      ]"
                      :class="requiredFields.education && 'required'"
                      hide-details
                      @input="trimField($event, 'education')"
                    />
                  </VCol>

                  <VCol cols="12">
                    <VCombobox
                      v-model="profile.educationLevel"
                      :items="educationOptions"
                      :label="$t('education_level.one')"
                      outlined
                      dense
                      :maxlength="127"
                      hide-selected
                      :rules="[
                        v => preparedRule(requiredFields.education_level, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов'
                      ]"
                      :class="requiredFields.education_level && 'required'"
                    />
                  </VCol>
                </VRow>
                <VDivider class="mb-4" />
                <VCard color="#f5f5f5">
                  <VCardTitle>
                    <span :class="requiredFields.previous_certificate && 'required'">{{ $t('information_doc_education_of_the_user.one') }}:</span>
                  </VCardTitle>
                  <VCardText>
                    <VCard
                      v-for="(certificate, idx) in profile.certificates"
                      :key="idx"
                      :class="{ 'mb-4': idx !== profile.certificates.length }"
                    >
                      <VListItem>
                        <VListItemContent />
                        <VListItemAction>
                          <VBtn
                            icon
                            color="error"
                            :title="$t('delete.one')"
                            @click="deleteCertificate(idx)"
                          >
                            <VIcon>close</VIcon>
                          </VBtn>
                        </VListItemAction>
                      </VListItem>
                      <VDivider />
                      <VCardText>
                        <VRow>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VSelect
                              v-model="certificate.level"
                              dense
                              hide-details
                              :items="certificateLevels"
                              clearable
                              :label="$t('education_level.one')"
                              outlined
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @change="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.fullname"
                              dense
                              hide-details
                              outlined
                              :label="$t('certificate_full_name.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.series"
                              dense
                              hide-details
                              outlined
                              :label="$t('series.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.number"
                              dense
                              hide-details
                              outlined
                              :label="$t('numbers.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.signed_at"
                              dense
                              hide-details
                              outlined
                              type="date"
                              :label="$t('signed_at.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.edu"
                              dense
                              hide-details
                              outlined
                              :label="$t('educational_institution.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.edu_level"
                              dense
                              hide-details
                              outlined
                              :label=" $t('educational_institution_level.one')"
                              :rules="[
                                v => preparedRule(requiredFields.previous_certificate, v) || (v || '').trim().length > 0 || 'Заполните поле',
                              ]"
                              :class="requiredFields.previous_certificate && 'required'"
                              @input="handleСhanges()"
                            />
                          </VCol>
                        </VRow>
                      </VCardText>
                    </VCard>
                  </VCardText>
                  <VCardActions>
                    <VBtn
                      color="#fff"
                      depressed
                      @click="addCertificate"
                    >
                      {{ $t('add.one') }} {{ $t('documents.one') }}
                    </VBtn>
                  </VCardActions>
                </VCard>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save('education-form')"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VForm>

          <!-- Работа -->
          <VForm
            v-show="currentItem === 'work'"
            ref="work-form"
            data-tab="work"
            class="fill-height"
            lazy-validation
            @submit.prevent="save('work-form')"
          >
            <VCard flat>
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('$app.work') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.employer"
                      outlined
                      :label="$t('employers.one')"
                      :rules="[
                        v => preparedRule(requiredFields.employer, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов'
                      ]"
                      :class="requiredFields.employer && 'required'"
                      @input="trimField($event, 'employer')"
                    />
                    <VTextField
                      v-model="profile.position"
                      outlined
                      :label="$t('user_profile_position.one')"
                      :rules="[
                        v => preparedRule(requiredFields.position, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов'
                      ]"
                      :class="requiredFields.position && 'required'"
                      @input="trimField($event, 'position')"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save('work-form')"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VForm>
        </Component>
      </div>
    </VCard>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import innValidator from '@/mixins/innValidator'
import { updateUser, getUserSettings, getUser } from '@/api/api'
import store from '@/store/store'
import { COUNTRIES } from '@/utils/countries'
import { TIMEZONES } from '@/utils/timezones'
import { validateSNILS } from '../utils/snilsValidator'
import { UDate } from '@/utils/date'
import { VDialog } from 'vuetify/lib'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import { mask } from '@titou10/v-mask'

class Profile {
  constructor (account = {}, settings = {}) {
    this.title = account.title || ''
    this.titleRomanized = account.title_romanized || ''
    this.titleDative = account.title_dative || ''
    this.titleAccusative = account.title_accusative || ''
    this.titleGenitive = account.title_genitive || ''
    this.username = account.username || ''
    this.password = account.password || ''
    this.position = account.position || ''
    this.email = account.profile?.email || ''
    this.phone = account.profile?.phone || ''
    this.photo = account.profile?.photo || ''
    this.employer = account.profile?.employer || ''
    this.inn = account.profile?.inn || ''
    this.snils = account.profile?.snils || ''
    this.gender = account.profile?.gender || ''
    this.dob = UDate.formatDate(account.profile?.dob || '')
    this.citizenship = account.profile?.citizenship || ''
    this.passport = account.profile?.passport || ''
    this.locality = account.profile?.locality || ''
    this.address = account.profile?.address || ''
    this.physicalAddress = account.profile?.physical_address || ''
    this.postalAddress = account.profile?.postal_address || ''
    this.education = account.profile?.education || ''
    this.educationLevel = account.profile?.education_level || ''
    this.certificates = account.profile?.certificates || []
    this.timezone = settings.timezone || ''
    this.generatePassword = 0
    this.sendCredentials = false
  }
}

function makeObservable (target, handler) {
  return new Proxy(target, {
    set () {
      const success = Reflect.set(...arguments)
      if (success && handler) {
        handler()
      }

      return success
    }
  })
}

export default {
  name: 'TheUserEdit',
  components: { VDialog },
  directives: { mask },
  mixins: [documentTitle, innValidator],

  beforeRouteEnter (to, from, next) {
    const { id, profile } = store.getters[getters.ACCOUNT]
    if (!profile.can_change_himself) {
      next({ name: 'user', params: { userID: id } })
    }

    next()
  },

  data () {
    return {
      currentItem: '',
      isSubmitting: false,
      isDeletePhoto: 0,
      isPasswordVisible: false,
      hasChanged: false,
      timezoneItems: TIMEZONES,
      citizenshipItems: COUNTRIES,
      certificateLevels: [
        { text: 'Высшее образование', value: 'Высшее образование' },
        { text: 'Среднее профессиональное образование', value: 'Среднее профессиональное образование' },
        { text: 'Справка', value: 'Справка' },
        { text: 'Стаж', value: 'Стаж' },
        { text: 'Пункт 1 правил формирования ФИС ФРДО', value: 'Пункт 1 правил формирования ФИС ФРДО' }
      ],
      educationOptions: [
        'Дошкольное образование',
        'Начальное общее образование',
        'Основное общее образование',
        'Среднее общее образование',
        'Среднее профессиональное образование',
        'Высшее образование — бакалавриат',
        'Высшее образование — специалитет, магистратура',
        'Высшее образование — подготовка кадров высшей квалификации',
        'Справка',
        'Стаж',
      ],
      profile: {},
      validateSNILS
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR
    }),

    component () {
      return this.$vuetify.breakpoint.smAndDown ? 'v-dialog' : 'div'
    },

    requiredFields () {
      const fields = {}
      this.vendor?.branding?.required_listener_profile_fields.forEach(field => {
        fields[field] = true
      })

      return fields
    },

    props () {
      if (this.component === 'v-dialog') {
        return {
          transition: 'slide-x-transition',
          fullscreen: true,
          value: this.currentItem
        }
      }

      return {}
    }
  },

  watch: {
    currentItem: {
      handler: 'updateItem'
    }
  },

  created () {
    this.register()
  },

  mounted () {
    const query = this.$route.query

    if (query._field) {
      this.changeTabByField(query._field)
    }
  },

  methods: {
    ...mapActions({
      setAccount: actions.SET_ACCOUNT,
      createNotification: actions.CREATE_NOTIFICATION
    }),

    uploadPhoto (evt) {
      this.profile.photo = evt.target.files[0]
      this.save('avatar-form')
    },

    deletePhoto () {
      this.isDeletePhoto = 1
      this.save('avatar-form')
    },

    deleteCertificate (idx) {
      this.profile.certificates.splice(idx, 1)
      this.profile.certificates = [...this.profile.certificates]
    },

    updateItem (newVal) {
      const query = Object.assign({}, this.$route.query, {
        settings: newVal
      })

      this.$router.replace({ query }).catch(() => {})
    },

    addCertificate () {
      this.profile.certificates = [...this.profile.certificates, {
        level: '',
        fullname: '',
        series: '',
        number: '',
        edu: '',
        edu_level: '',
        signed_at: '',
        key: this.profile.certificates.length
      }]
    },

    trimField (v, field) {
      this[field] = (v || '').trim()
    },

    collectData (ref) {
      const profile = this.profile
      const formData = new FormData()
      switch (ref) {
        case 'avatar-form':
          formData.append('photo', profile.photo)
          formData.append('delete_photo', this.isDeletePhoto)
          break
        case 'account-form':
          formData.append('username', profile.username)
          formData.append('password', profile.password === this.account.password ? '' : profile.password)
          formData.append('generate_password', Number(profile.generatePassword))
          formData.append('send_credentials', profile.sendCredentials)
          formData.append('timezone', profile.timezone)
          break
        case 'base-information-form':
          formData.append('title', profile.title)
          formData.append('title_romanized', profile.titleRomanized)
          formData.append('title_genitive', profile.titleGenitive)
          formData.append('title_dative', profile.titleDative)
          formData.append('title_accusative', profile.titleAccusative)
          formData.append('profile[person][gender]', profile.gender)
          formData.append('profile[person][dob]', UDate.formatDateISO(profile.dob))
          formData.append('profile[person][citizenship]', profile.citizenship)
          formData.append('profile[person][inn]', profile.inn)
          formData.append('profile[person][snils]', profile.snils)
          formData.append('profile[person][phone]', profile.phone)
          formData.append('profile[person][email]', profile.email)
          formData.append('profile[person][passport]', profile.passport)
          break
        case 'contacts-information-form':
          formData.append('profile[person][locality]', profile.locality)
          formData.append('profile[person][address]', profile.address)
          formData.append('profile[person][physical_address]', profile.physicalAddress)
          formData.append('profile[person][postal_address]', profile.postalAddress)
          break
        case 'education-form':
          formData.append('profile[person][education]', profile.education)
          formData.append('profile[person][education_level]', profile.educationLevel || '')
          break
        case 'work-form':
          formData.append('profile[person][employer]', profile.employer)
          formData.append('position', profile.position)
          break
      }

      profile.certificates.forEach((certificate, idx) => {
        if (Object.values(certificate).some(value => !!value)) {
          formData.append('profile[certificate][key]', idx)
          formData.append('profile[certificate][level]', certificate.level || '')
          formData.append('profile[certificate][fullname]', certificate.fullname || '')
          formData.append('profile[certificate][series]', certificate.series || '')
          formData.append('profile[certificate][number]', certificate.number || '')
          formData.append('profile[certificate][signed_at]', certificate.signed_at || '')
          formData.append('profile[certificate][edu]', certificate.edu || '')
          formData.append('profile[certificate][edu_level]', certificate.edu_level || '')
        }
      })

      return formData
    },

    handleСhanges () {
      this.hasChanged = true
    },

    async save (ref) {
      const form = this.$refs[ref]
      if (!form || !form.validate()) {
        return this.createNotification({
          msg: 'Заполните все поля!',
          type: 'error'
        })
      }
      this.isSubmitting = true

      const { user } = await updateUser(this.account.id, this.collectData(ref))
      this.setAccount(user)

      const { settings } = await getUserSettings(this.account.id)
      this.settings = settings
      this.profile = makeObservable(new Profile(this.account, this.settings), this.handleСhanges)

      this.createNotification({
        msg: this.$t('$app.changes_saved'),
        type: 'success'
      })

      this.hasChanged = false
      this.isSubmitting = false

      if (!this.profile?.certificates?.length) {
        this.addCertificate()
      }
      const fields = this.$refs['user-edit'].querySelectorAll('.required input, .required textarea')
      const field = Array.from(fields).find(f => !f.value && !f.attributes.readonly)
      const tab = field?.closest('form[data-tab]')?.dataset?.tab

      if (tab) {
        this.currentItem = tab
        return
      }

      const route = this.$route?.query?._from
      if (route) {
        document.location.href = document.location.origin + route
      }
    },

    async register () {
      const query = this.$route.query
      if (query.settings) {
        this.currentItem = query.settings
      }

      const { user } = await getUser(this.account.id)
      if (!user) {
        return
      }

      this.setAccount(user)
      this.documentTitle = this.account.title

      const { settings } = await getUserSettings(this.account.id)
      this.settings = settings
      this.profile = makeObservable(new Profile(this.account, this.settings), this.handleСhanges)
    },

    updateINN (event) {
      this.profile.inn = this.filterINN(event)
    },

    preparedRule (required, value) {
      const length = (value || '').trim().length
      return !required && !length
    },

    changeTabByField (label) {
      const container = this.$refs['user-edit']
      if (container) {
        const labels = Array.from(container.querySelectorAll('.v-input label, .v-input legend, span.required'))
        const field = (labels || []).find(l => l.textContent.toLowerCase().includes(label.toLowerCase().trim()))
        const tab = field?.closest('[data-tab]')?.dataset?.tab
        this.currentItem = tab || 'base-information'
      }
    }
  }
}
</script>

<style lang="scss">
.user-edit {
  height: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 1rem;
  }
}

form[data-user-edit-avatar] {
  grid-area: avatar;
}

.user-edit__avatar {
  background-color: #ccc;

  .v-icon {
    font-size: 40px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 160px;
    }
  }
}

.user-edit__title {
  grid-area: title;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
}

.user-edit__content {
  grid-area: content;
  max-width: 60rem;
}

.user-edit__body {
  height: 100%;
  padding: 1rem;
  display: grid;
  gap: 0.75rem;
  grid-template-areas:
    "avatar title"
    "actions actions"
    "content content";
  grid-template-columns: 80px 1fr;
  grid-auto-rows: min-content;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 256px 1fr;
    grid-template-areas:
      "avatar title"
      "avatar content"
      "actions content"
      ". content";
  }

  > .actions {
    grid-area: actions;
  }
}
</style>
